import { TOrder } from 'components/table/EnhancedTableHead';
import { DataTypesExtendedStructured } from 'models/dataTypesExtended/dto';
import { composeComparators, getComparator } from 'models/modelUtils/comparator';

// The purpose of this utility is to provide custom sorting capability on top of regular table sorting.
//
// To simplify, we want to show _new_ data types (that client hasn't seen yet) always on top, _unused_ data
// types (that aren't present in client infra) always on bottom, and the rest in-between; then apply
// regular sorting within these three groups.
// Actual custom logic can be more complex than that, as needed by product.

function getPriority(dataType: DataTypesExtendedStructured) {
	if (dataType.alias === 'PII') return -3;
	if (dataType.alias === 'PCI') return -2;
	if (dataType.alias === 'DEVELOPER_SECRETS') return -1;

	const unknownAndUsed = !dataType.known && dataType.isUsed;
	const knownAndUsed = dataType.known && dataType.isUsed;
	const unused = !dataType.isUsed && !dataType.is_disabled;

	if (unknownAndUsed) return 1;
	if (knownAndUsed) return 2;
	if (unused) return 3;

	return 4;
}

function customComparator(a: DataTypesExtendedStructured, b: DataTypesExtendedStructured) {
	return getPriority(a) - getPriority(b);
}

type Props = {
	params: { sort: { value: string; operator: TOrder } };
	data: DataTypesExtendedStructured[];
};

function sortedDataHandler({ params, data }: Props) {
	const orderBy = params.sort.value;
	const order = params.sort.operator;

	const regularComparator = getComparator(orderBy, order);

	return [...data].sort(composeComparators(customComparator, regularComparator));
}

export { sortedDataHandler };
