import ButtonIcon from 'components/ButtonIcon';
import { ConfirmModal, getConfirmation } from 'components/ConfirmModal';
import { enqueueSnackbar } from 'components/Snackbar';
import rowStyles from 'components/table/GridRow/index.module.pcss';
import Typo from 'components/typography/Typo';
import { AccountAWSItem } from 'models/sensors/aws/dto';
import { deleteAccountAWSFx } from 'models/sensors/aws/effects';
import styles from '../index.module.css';

type Props = {
	name: AccountAWSItem['external_id'];
	id: AccountAWSItem['id'];
};

export function DraftDeleteButton({ name, id }: Props) {
	async function onDelete() {
		if (!(await confirm(name))) {
			return;
		}

		await deleteAccountAWSFx(id);

		enqueueSnackbar('Draft deleted');
	}

	async function confirm(externalId: string) {
		const confirmDeletionModal = (
			<ConfirmModal
				title="Delete draft"
				confirmProps={{
					children: 'Delete',
				}}
				disableUntilEntered={externalId}
				placeholder="AWS External ID"
			>
				<Typo variant="D/Regular/Body-S" className={styles.modalText}>
					AWS connection draft will be deleted. <br />
					Enter the AWS External ID to confirm the deletion.
				</Typo>
			</ConfirmModal>
		);

		return await getConfirmation(confirmDeletionModal);
	}

	return (
		<ButtonIcon
			size="S"
			icon="Delete/Filled"
			onClick={onDelete}
			className={rowStyles.showWhenFocus}
			dataTest="aws-account-draft-delete-button"
		/>
	);
}
